export default function getSurveyValues(values, surveyConfig) {
  const surveyValues = {};
  const requirementsMap = surveyConfig?.spec?.reduce((map, obj) => {
    map[obj.variable] = obj.required;
    return map;
  }, {});
  Object.keys(values).forEach((key) => {
    if (key.startsWith('survey_') && values[key] !== []) {
      const keyName = key.substr('survey_'.length);
      if (
        Array.isArray(values[key]) &&
        values[key].length === 0 &&
        requirementsMap[keyName]
      ) {
        return;
      }
      if (values[key] === '' && requirementsMap[keyName]) {
        return;
      }
      surveyValues[keyName] = values[key];
    }
  });
  return surveyValues;
}

import React, { useCallback, useEffect, useState } from 'react';
import { Select, SelectOption, SelectVariant } from '@patternfly/react-core';
import { t } from '@lingui/macro';
import useRequest, { useDismissableError } from '../../hooks/useRequest';
import { OrganizationsAPI } from '../../api';
import ErrorDetail from '../ErrorDetail';
import AlertModal from '../AlertModal';
import useDebounce from '../../hooks/useDebounce';
import { useFilters } from '../../contexts/Filters';
import { ORGANIZATION_FILTER_KEY } from '../../constants';

function OrganizationFilter() {
  const [filterString, setFilterString] = useState('');
  const { filteredOrganizations, setFilteredOrganizations } = useFilters();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const {
    error: configError,
    isLoading,
    request: fetchOrganizations,
    result: { organizations },
  } = useRequest(
    useCallback(async () => {
      const response = await OrganizationsAPI.readProtected({
        ...(filterString && { name__icontains: filterString }),
        order_by: 'name',
        page_size: 7,
      });
      return { organizations: response.data.results };
    }, [filterString]),
    { organizations: [] }
  );

  useEffect(() => {
    const organizationsStorage = sessionStorage.getItem(
      ORGANIZATION_FILTER_KEY
    );
    if (organizationsStorage && JSON.parse(organizationsStorage).length !== 0) {
      setFilteredOrganizations(JSON.parse(organizationsStorage));
    }
  }, [setFilteredOrganizations]);

  useEffect(() => {
    sessionStorage.setItem(
      ORGANIZATION_FILTER_KEY,
      JSON.stringify(filteredOrganizations)
    );
  }, [filteredOrganizations]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const { error, dismissError } = useDismissableError(configError);

  const orgOptions = (organizations || []).map(({ id, name }) => ({
    value: name,
    id,
  }));

  const handleSelectToggle = (isOpen) => {
    setIsSelectOpen(isOpen);
  };

  const handleSelect = (event, selection) => {
    setFilteredOrganizations((prevState) =>
      prevState.includes(selection)
        ? prevState.filter((item) => item !== selection)
        : [...prevState, selection]
    );
  };

  const handleClearSelect = () => {
    setIsSelectOpen(false);
    setFilteredOrganizations([]);
  };

  const handleSearchInputChange = useDebounce(setFilterString, 500);

  return (
    <div>
      {error && (
        <AlertModal
          isOpen
          variant="error"
          title={t`Error!`}
          onClose={dismissError}
          ouiaId="organizations-error-modal"
        >
          {t`Failed to retrieve organizations list.`}
          <ErrorDetail error={error} />
        </AlertModal>
      )}

      <Select
        variant={SelectVariant.typeaheadMulti}
        onToggle={handleSelectToggle}
        onSelect={handleSelect}
        onClear={handleClearSelect}
        selections={filteredOrganizations}
        isOpen={isSelectOpen}
        isPlain
        placeholderText={t`Select organizations`}
        onTypeaheadInputChanged={handleSearchInputChange}
        {...(isLoading && { loadingVariant: 'spinner' })}
      >
        {orgOptions.map(({ id, value }) => (
          <SelectOption key={`organization-${id}`} value={value} />
        ))}
      </Select>
    </div>
  );
}

export default OrganizationFilter;

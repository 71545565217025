import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { AboutModal } from '@patternfly/react-core';
import useBrandName from 'hooks/useBrandName';

function About({ version, isOpen, onClose }) {
  const brandName = useBrandName();

  const createSpeechBubble = () => {
    let text = '';
    if (typeof brandName === 'string' && brandName.length > 0) {
      text =
        brandName.indexOf('AWX') === -1
          ? `${brandName} ${version}`
          : `${brandName} ${version}`;
    }

    let top = '';
    let bottom = '';

    for (let i = 0; i < text.length; i++) {
      top += '_';
      bottom += '-';
    }

    top = ` __${top}__ \n`;
    text = `<  ${text}  >\n`;
    bottom = ` --${bottom}-- `;

    return top + text + bottom;
  };

  const speechBubble = createSpeechBubble();
  const copyright = t`Copyright`;
  const astraLLC = t`LLC «RusBITech-ASTRA»`;

  return (
    <AboutModal
      isOpen={isOpen}
      onClose={onClose}
      productName={brandName}
      trademark={`${copyright} ${new Date().getFullYear()} ${astraLLC}`}
      brandImageSrc="static/media/logo-header.svg"
      brandImageAlt={t`Brand Image`}
    >
      <pre style={{ fontSize: 11 }}>
        {speechBubble}
        {`
          \\
           \\           ___
            \\       .-'   \`'.
             \\     /         \\
              \\    |         ;
                   |         |           ___.--,
          _.._     |0) ~ (0) |    _.---'\`__.-( (_.
   __.--'\`_.. '.__.\\    '--. \\_.-' ,.--'\`     \`""\`
  ( ,.--'\`   ',__ /./;   ;, '.__.'\`    __
  _\`) )  .---.__.' / |   |\\   \\__..--""  """--.,_
 \`---' .'.''-._.-'\`_./  /\\ '.  \\ _.-~~~\`\`\`\`~~~-._\`-.__.'
       | |  .' _.-' |  |  \\  \\  '.               \`~---\`
        \\ \\/ .'     \\  \\   '. '-._)
         \\/ /        \\  \\    \`=.__\`~-.
         / /\\         \`) )    / / \`"".\`\\
   , _.-'.'\\ \\        / /    ( (     / /
    \`--~\`   ) )    .-'.'      '.'.  | (
           (/\`    ( (\`          ) )  '-;
            \`      '-;         (-'
                    `}
      </pre>
    </AboutModal>
  );
}

About.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  version: PropTypes.string,
};

About.defaultProps = {
  isOpen: false,
  version: null,
};

export default About;

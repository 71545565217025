import React, { useContext, useMemo } from 'react';

const FilterContext = React.createContext({});
FilterContext.displayName = 'FilterContext';

const useFilters = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a ConfigProvider');
  }
  return context;
};

const FilterProvider = ({ children }) => {
  const [filteredOrganizations, setFilteredOrganizations] = React.useState([]);
  const value = useMemo(
    () => ({ filteredOrganizations, setFilteredOrganizations }),
    [filteredOrganizations, setFilteredOrganizations]
  );
  if (!children) return null;
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export { FilterContext, FilterProvider, useFilters };

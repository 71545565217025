import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { number, shape } from 'prop-types';
import { t } from '@lingui/macro';
import {
  AdHocCommandsAPI,
  InventorySourcesAPI,
  JobsAPI,
  JobTemplatesAPI,
  ProjectsAPI,
  SystemJobTemplatesAPI,
  WorkflowJobsAPI,
  WorkflowJobTemplatesAPI,
} from 'api';
import useToast, { AlertVariant } from 'hooks/useToast';
import AlertModal from '../AlertModal';
import ErrorDetail from '../ErrorDetail';
import LaunchPrompt from '../LaunchPrompt';

function canLaunchWithoutPrompt(launchData) {
  return (
    launchData.can_start_without_user_input &&
    !launchData.ask_inventory_on_launch &&
    !launchData.ask_credential_on_launch &&
    !launchData.ask_variables_on_launch &&
    !launchData.ask_limit_on_launch &&
    !launchData.ask_scm_branch_on_launch &&
    !launchData.ask_execution_environment_on_launch &&
    !launchData.ask_labels_on_launch &&
    !launchData.ask_forks_on_launch &&
    !launchData.ask_job_slice_count_on_launch &&
    !launchData.ask_timeout_on_launch &&
    !launchData.ask_instance_groups_on_launch &&
    !launchData.survey_enabled &&
    (!launchData.passwords_needed_to_start ||
      launchData.passwords_needed_to_start.length === 0) &&
    (!launchData.variables_needed_to_start ||
      launchData.variables_needed_to_start.length === 0)
  );
}

function LaunchButton({ resource, children }) {
  const history = useHistory();
  const [showLaunchPrompt, setShowLaunchPrompt] = useState(false);
  const [launchConfig, setLaunchConfig] = useState(null);
  const [surveyConfig, setSurveyConfig] = useState(null);
  const [labels, setLabels] = useState([]);
  const [isLaunching, setIsLaunching] = useState(false);
  const [resourceCredentials, setResourceCredentials] = useState([]);
  const [error, setError] = useState(null);
  const { addToast, Toast, toastProps } = useToast();

  const showToast = () => {
    addToast({
      id: resource.id,
      title: t`A job has already been launched`,
      variant: AlertVariant.info,
      hasTimeout: true,
    });
  };

  const handleLaunch = async () => {
    if (isLaunching) {
      showToast();
      return;
    }
    setIsLaunching(true);
    let readLaunch;
    let readSurvey;
    let readLabels;
    let resourceTemplateId;
    let resourceTemplateType;

    switch (resource.type) {
      case 'schedule':
        resourceTemplateId = resource.summary_fields.unified_job_template.id;
        resourceTemplateType =
          resource.summary_fields.unified_job_template.unified_job_type;
        break;
      default:
        resourceTemplateId = resource.id;
        resourceTemplateType = resource.type;
        break;
    }

    if (resourceTemplateType === 'workflow_job_template') {
      readLaunch = WorkflowJobTemplatesAPI.readLaunch(resourceTemplateId);
      readSurvey = WorkflowJobTemplatesAPI.readSurvey(resourceTemplateId);
      readLabels = WorkflowJobTemplatesAPI.readAllLabels(resourceTemplateId);
    } else if (resourceTemplateType === 'system_job') {
      readLaunch = SystemJobTemplatesAPI.readLaunch(resourceTemplateId);
    } else {
      readLaunch = JobTemplatesAPI.readLaunch(resourceTemplateId);
      readSurvey = JobTemplatesAPI.readSurvey(resourceTemplateId);
      readLabels = JobTemplatesAPI.readAllLabels(resourceTemplateId);
    }

    try {
      const { data: launch } = await readLaunch;
      setLaunchConfig(launch);
      if (launch.survey_enabled) {
        const { data } = await readSurvey;
        setSurveyConfig(data);
      }

      if (launch.ask_labels_on_launch) {
        const {
          data: { results },
        } = await readLabels;

        const allLabels = results.map((label) => ({
          ...label,
          isReadOnly: true,
        }));

        setLabels(allLabels);
      }

      if (launch.ask_credential_on_launch) {
        if (resourceTemplateType === 'workflow_job_template') {
          const {
            data: { results: templateCredentials },
          } = await WorkflowJobTemplatesAPI.readCredentials(resource.id);
          setResourceCredentials(templateCredentials);
        } else {
          const {
            data: { results: templateCredentials },
          } = await JobTemplatesAPI.readCredentials(resource.id);
          setResourceCredentials(templateCredentials);
        }
      }

      if (canLaunchWithoutPrompt(launch)) {
        await launchWithParams({});
      } else {
        setShowLaunchPrompt(true);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLaunching(false);
    }
  };

  const launchWithParams = async (params) => {
    if (isLaunching) {
      showToast();
      return;
    }

    setIsLaunching(true);

    try {
      let jobPromise;
      let jobTemplateId;
      let jobTemplateType;

      switch (resource.type) {
        case 'schedule':
          jobTemplateId = resource.summary_fields.unified_job_template.id;
          jobTemplateType =
            resource.summary_fields.unified_job_template.unified_job_type;
          break;
        default:
          jobTemplateId = resource.id;
          jobTemplateType = resource.type;
          break;
      }

      if (
        jobTemplateType === 'job_template' ||
        (resource.type === 'schedule' && jobTemplateType === 'job')
      ) {
        jobPromise = JobTemplatesAPI.launch(jobTemplateId, params || {});
      } else if (
        jobTemplateType === 'workflow_job_template' ||
        (resource.type === 'schedule' && jobTemplateType === 'workflow_job')
      ) {
        jobPromise = WorkflowJobTemplatesAPI.launch(
          jobTemplateId,
          params || {}
        );
      } else if (jobTemplateType === 'system_job') {
        if (params?.extra_vars) {
          params.extra_vars = resource.extra_data;
        }
        jobPromise = SystemJobTemplatesAPI.launch(jobTemplateId, params || {});
      } else if (jobTemplateType === 'job') {
        jobPromise = JobsAPI.relaunch(jobTemplateId, params || {});
      } else if (jobTemplateType === 'workflow_job') {
        jobPromise = WorkflowJobsAPI.relaunch(jobTemplateId, params || {});
      } else if (jobTemplateType === 'ad_hoc_command') {
        if (params?.credential_passwords) {
          // The api expects the passwords at the top level of the object instead of nested
          // in credential_passwords like the other relaunch endpoints
          Object.keys(params.credential_passwords).forEach((key) => {
            params[key] = params.credential_passwords[key];
          });

          delete params.credential_passwords;
        }
        jobPromise = AdHocCommandsAPI.relaunch(jobTemplateId, params || {});
      }

      const { data: job } = await jobPromise;
      history.push(`/jobs/${job.id}/output`);
    } catch (launchError) {
      setError(launchError);
    } finally {
      setIsLaunching(false);
    }
  };

  const handleRelaunch = async (params) => {
    let readRelaunch;
    let relaunch;

    if (isLaunching) {
      showToast();
      return;
    }
    setIsLaunching(true);
    if (resource.type === 'inventory_update') {
      // We'll need to handle the scenario where the src no longer exists
      readRelaunch = InventorySourcesAPI.readLaunchUpdate(
        resource.inventory_source
      );
    } else if (resource.type === 'project_update') {
      // We'll need to handle the scenario where the project no longer exists
      readRelaunch = ProjectsAPI.readLaunchUpdate(resource.project);
    } else if (resource.type === 'workflow_job') {
      readRelaunch = WorkflowJobsAPI.readRelaunch(resource.id);
    } else if (resource.type === 'ad_hoc_command') {
      readRelaunch = AdHocCommandsAPI.readRelaunch(resource.id);
    } else if (resource.type === 'job') {
      readRelaunch = JobsAPI.readRelaunch(resource.id);
    }

    try {
      const { data: relaunchConfig } = await readRelaunch;
      setLaunchConfig(relaunchConfig);
      if (
        !relaunchConfig.passwords_needed_to_start ||
        relaunchConfig.passwords_needed_to_start.length === 0
      ) {
        if (resource.type === 'inventory_update') {
          relaunch = InventorySourcesAPI.launchUpdate(
            resource.inventory_source
          );
        } else if (resource.type === 'project_update') {
          relaunch = ProjectsAPI.launchUpdate(resource.project);
        } else if (resource.type === 'workflow_job') {
          relaunch = WorkflowJobsAPI.relaunch(resource.id);
        } else if (resource.type === 'ad_hoc_command') {
          relaunch = AdHocCommandsAPI.relaunch(resource.id);
        } else if (resource.type === 'job') {
          relaunch = JobsAPI.relaunch(resource.id, params || {});
        }
        const { data: job } = await relaunch;
        history.push(`/jobs/${job.id}/output`);
      } else {
        setShowLaunchPrompt(true);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLaunching(false);
    }
  };

  return (
    <>
      {children({
        handleLaunch,
        handleRelaunch,
        isLaunching,
      })}
      <Toast {...toastProps} />
      {error && (
        <AlertModal
          isOpen={error}
          variant="error"
          title={t`Error!`}
          onClose={() => setError(null)}
        >
          {t`Failed to launch job.`}
          <ErrorDetail error={error} />
        </AlertModal>
      )}
      {showLaunchPrompt && (
        <LaunchPrompt
          launchConfig={launchConfig}
          surveyConfig={surveyConfig}
          resource={resource}
          labels={labels}
          onLaunch={launchWithParams}
          onCancel={() => setShowLaunchPrompt(false)}
          resourceDefaultCredentials={resourceCredentials}
        />
      )}
    </>
  );
}

LaunchButton.propTypes = {
  resource: shape({
    id: number.isRequired,
  }).isRequired,
};

export default LaunchButton;

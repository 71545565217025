import { useConfig } from 'contexts/Config';
import getDocsBaseUrl from './getDocsBaseUrl';

export default function GetDocsUrl(path) {
  const config = useConfig();
  const pathToLinkMap = {
    '/home': `${getDocsBaseUrl(config)}ui/views/dashboard/`,
    '/jobs': `${getDocsBaseUrl(config)}ui/views/jobs/`,
    '/schedules': `${getDocsBaseUrl(config)}ui/views/schedules`,
    '/activity_stream': `${getDocsBaseUrl(config)}ui/views/activity-stream`,
    '/workflow_approvals': `${getDocsBaseUrl(
      config
    )}ui/views/workflow-approvals`,
    '/host_metrics': `${getDocsBaseUrl(config)}ui/views/host-metrics`,
    '/templates': `${getDocsBaseUrl(config)}ui/resources/templates`,
    '/credentials': `${getDocsBaseUrl(config)}ui/resources/credentials`,
    '/projects': `${getDocsBaseUrl(config)}ui/resources/projects`,
    '/inventories': `${getDocsBaseUrl(config)}ui/resources/inventory`,
    '/hosts': `${getDocsBaseUrl(config)}ui/resources/hosts`,
    '/organizations': `${getDocsBaseUrl(config)}ui/access/organizations`,
    '/users': `${getDocsBaseUrl(config)}ui/access/users`,
    '/teams': `${getDocsBaseUrl(config)}ui/access/teams`,
    '/credential_types': `${getDocsBaseUrl(
      config
    )}ui/administration/credential-types`,
    '/notification_templates': `${getDocsBaseUrl(
      config
    )}ui/administration/notifications`,
    '/management_jobs': `${getDocsBaseUrl(
      config
    )}ui/administration/management-jobs`,
    '/instance_groups': `${getDocsBaseUrl(
      config
    )}ui/administration/instance-groups`,
    '/instances': `${getDocsBaseUrl(config)}ui/administration/instances`,
    '/applications': `${getDocsBaseUrl(config)}ui/administration/applications`,
    '/execution_environments': `${getDocsBaseUrl(
      config
    )}ui/administration/execution-environments`,
    '/topology_view': `${getDocsBaseUrl(
      config
    )}ui/administration/topology-view`,
    '/settings': `${getDocsBaseUrl(config)}ui/settings`,
    '/settings/azure/details': `${getDocsBaseUrl(config)}ui/settings/auth`,
    '/settings/github/default/details': `${getDocsBaseUrl(
      config
    )}ui/settings/auth`,
    '/settings/google_oauth2/details': `${getDocsBaseUrl(
      config
    )}ui/settings/auth`,
    '/settings/ldap/default/details': `${getDocsBaseUrl(
      config
    )}ui/settings/auth`,
    '/settings/radius/details': `${getDocsBaseUrl(config)}ui/settings/auth`,
    '/settings/saml/details': `${getDocsBaseUrl(config)}ui/settings/auth`,
    '/settings/tacacs/details': `${getDocsBaseUrl(config)}ui/settings/auth`,
    '/settings/oidc/details': `${getDocsBaseUrl(config)}ui/settings/auth`,
    '/settings/jobs/details': `${getDocsBaseUrl(config)}ui/settings/jobs`,
    '/settings/miscellaneous_system/details': `${getDocsBaseUrl(
      config
    )}ui/settings/system`,
    '/settings/miscellaneous_authentication/details': `${getDocsBaseUrl(
      config
    )}ui/settings/system`,
    '/settings/logging/details': `${getDocsBaseUrl(config)}ui/settings/system`,
    '/settings/ui/details': `${getDocsBaseUrl(config)}ui/settings/ui`,
  };

  return (
    pathToLinkMap[path.pathname] ||
    'https://docs.astra-automation.ru/latest/controller/'
  );
}

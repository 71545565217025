import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { Button, FormGroup, TextInput, Tooltip } from '@patternfly/react-core';
import { RocketIcon } from '@patternfly/react-icons';

import AlertModal from 'components/AlertModal';
import ResourcesTypesSelect from '../../../components/Schedule/shared/ResourcesTypesSelect';
import { SelectOption } from '../../../components/Schedule/shared/FrequencySelect';

const MAX_RETENTION = 99999;

const clamp = (val, min, max) => {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
};

function LaunchManagementPrompt({
  isOpen,
  isLoading,
  onClick,
  onClose,
  onConfirm,
  defaultDays,
  isPromptedForResourcesTypes,
  defaultResourcesTypes,
}) {
  const [dataRetention, setDataRetention] = useState(defaultDays);
  const [resourceTypes, setResourcesTypes] = useState(defaultResourcesTypes);

  return (
    <>
      <Tooltip content={t`Launch management job`} position="left">
        <Button
          aria-label={t`Launch management job`}
          variant="plain"
          onClick={onClick}
          isDisabled={isLoading}
        >
          <RocketIcon />
        </Button>
      </Tooltip>
      <AlertModal
        isOpen={isOpen}
        variant="info"
        onClose={onClose}
        title={t`Launch management job`}
        label={t`Launch management job`}
        actions={[
          <Button
            id="launch-job-confirm-button"
            key="delete"
            variant="primary"
            isDisabled={isLoading}
            aria-label={t`Launch`}
            onClick={() => onConfirm(dataRetention, resourceTypes)}
          >
            {t`Launch`}
          </Button>,
          <Button
            id="launch-job-cancel-button"
            key="cancel"
            variant="link"
            aria-label={t`Cancel`}
            onClick={onClose}
          >
            {t`Cancel`}
          </Button>,
        ]}
      >
        <FormGroup style={{ height: '420px' }}>
          {t`Set how many days of data should be retained`}
          <p style={{ marginBottom: '10px' }}>
            <TextInput
              value={dataRetention}
              type="number"
              onChange={(value) =>
                setDataRetention(clamp(value, 0, MAX_RETENTION))
              }
              aria-label={t`Data retention period`}
            />
          </p>
          {isPromptedForResourcesTypes === true && (
            <>
              {t`Choose resources that should be cleaned up.`}
              <ResourcesTypesSelect
                id="schedule-resources-types"
                onChange={setResourcesTypes}
                value={resourceTypes}
                placeholderText={t`Resources types`}
              >
                <SelectOption value="jobs">{t`Jobs`}</SelectOption>
                <SelectOption value="ad-hoc-commands">{t`Ad-Hoc Commands`}</SelectOption>
                <SelectOption value="project-updates">{t`Project updates`}</SelectOption>
                <SelectOption value="inventory-updates">{t`Inventory Updates`}</SelectOption>
                <SelectOption value="management-jobs">{t`Management Jobs`}</SelectOption>
                <SelectOption value="notifications">{t`Notifications`}</SelectOption>
                <SelectOption value="workflow-jobs">{t`Workflow Jobs`}</SelectOption>
              </ResourcesTypesSelect>
            </>
          )}
        </FormGroup>
      </AlertModal>
    </>
  );
}

export default LaunchManagementPrompt;

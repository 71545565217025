import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { PERSISTENT_FILTER_KEY } from '../../constants';
import { useFilters } from '../../contexts/Filters';

function getOrganizationFilterQS(filterKey, organizations) {
  if (!filterKey || !organizations || organizations.length === 0) return '';

  const key = `${filterKey}${
    filterKey !== 'organization' ? '.or__organization__name' : '.or__name'
  }`;

  const encodedOrganizations = organizations.map((org) => {
    const decodedOrg = decodeURIComponent(org);
    return encodeURIComponent(decodedOrg);
  });

  return `?${encodedOrganizations.map((org) => `${key}=${org}`).join('&')}`;
}

export default function PersistentFilters({ pageKey, filterKey, children }) {
  const location = useLocation();
  const history = useHistory();
  const { filteredOrganizations } = useFilters();

  const organizationFilterString = getOrganizationFilterQS(
    filterKey,
    filteredOrganizations
  );

  const updateSessionStorage = () => {
    const filter = {
      pageKey,
      qs: location.search,
      filterKey,
    };
    sessionStorage.setItem(PERSISTENT_FILTER_KEY, JSON.stringify(filter));
  };

  const updateURLBasedOnOrgFilters = () => {
    const currentParams = new URLSearchParams(location.search);
    const filterOrgName =
      filterKey === 'organization' ? '.or__name' : '.or__organization__name';

    currentParams.delete(`${filterKey}${filterOrgName}`);

    if (organizationFilterString) {
      const newParams = new URLSearchParams(organizationFilterString);
      newParams.forEach((value, key) => {
        currentParams.append(key, value);
      });

      const newSearch = currentParams.toString().replace(/\+/g, '%20');
      history.replace({ search: `?${newSearch}` });
    } else {
      const newSearch = currentParams.toString()
        ? `?${currentParams.toString()}`
        : '';
      if (newSearch !== location.search) {
        history.replace({ search: newSearch });
      }
    }
  };

  useEffect(updateSessionStorage, [location.search, pageKey, filterKey]);
  useEffect(updateURLBasedOnOrgFilters, [
    history,
    location.search,
    organizationFilterString,
    filterKey,
  ]);

  return children;
}

export function getPersistentFilters(key) {
  const filterString = sessionStorage.getItem(PERSISTENT_FILTER_KEY);
  const filter = filterString ? JSON.parse(filterString) : { qs: '' };

  return filter.pageKey === key ? filter.qs : '';
}

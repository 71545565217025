export default function getDocsBaseUrl(config) {
  // TODO: Return back once Astra Automation Controller docs are ready
  // let version = 'latest';
  // const licenseType = config?.license_info?.license_type;
  // if (licenseType && licenseType !== 'open') {
  //   version = config?.version ? config.version.split('-')[0] : 'latest';
  // }

  // TODO: This ugly hack exists only to make linter happy
  config = '';
  return `https://docs.astra-automation.ru/latest/controller/${config}`;
}
